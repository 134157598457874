<template>
    <div class="file-pick-cell">
        <div class="img" @click="startPicking" :title="$t('cms.click_to_select_image')">
            <img v-if="data && data.url" :src="data.url" :alt="data.alt" >
            <div v-else>
                {{ $t('cms.click_to_select_image') }}
            </div>
        </div>
        <teleport to="#app">
            <FilePicker :opened="pickFile" @close="stopPicking" @pickedFile="pickedFile" />
        </teleport>
    </div>
</template>

<script>
import {   defineAsyncComponent, ref } from 'vue';

export default {
    name: "FilePickCell",
    components: {
        FilePicker: defineAsyncComponent(() => import(/* webpackChunkName: "file-picker" */'@admin/components/cms/file/FilePicker')),
    },
    props: {
        data: {
            type: Object,
            required: false,
        }
    },
    emits: [
        "change"
    ],
    setup(props,context){
        const pickFile = ref(false);
        const filePicked = ref(null);
        const startPicking = () => {
            pickFile.value = true;
            filePicked.value = null;
        };
        const stopPicking = () => {
            pickFile.value = false;
        };
        const pickedFile = (data) => {
            context.emit('change',data);
            stopPicking();
        };

        return {
            filePicked,
            pickedFile,
            stopPicking,
            startPicking,
            pickFile,
        };

    }
}
</script>

<style scoped>
.file-pick-cell>.img{
    max-width: 100%;
    /* max-height: 30rem;
    min-height: 20rem; */
    height: 100%;
    margin: auto;
    display: block;
    cursor: pointer;
    border: 5px solid transparent;
    display: flex;
    justify-content: center;
    font-size: 1.4rem;
    flex-direction: column;
    align-items: center;
}
.file-pick-cell>.img:hover{
    border: 5px solid #177542;
}
.file-pick-cell>.img>img{
    max-height: 100%;
    max-width: 100%;
}
</style>
<template>
    <Modal class="content-page-form" @close="closeForm()" :opened="opened">
        <template v-if="createItem" v-slot:header>
            {{ $t('controls.add_new_item')}}
        </template>
        <template v-else v-slot:header>
            {{ $t('cms.edit_page_properties')}}
        </template>
        
        <template v-slot:body>
            <FilePickCell :data="data.image" @change="setImage($event)" />
            <BaseForm 
                :form_class="'form_small'" 
                @closeForm="closeForm()" 
                :cancelButton="''"
                :deleteButton="!createItem" >
                
                <template v-slot:homepage_row v-if="type === 'page'">
                    <div v-if="data.homepage" class="set_homepage">
                        {{ $t('cms.homepage')  }}
                    </div>
                    <BaseButton v-else-if="!createItem" type="button" class="btn-primary set_homepage" @click="set_homepage">
                        {{ $t('cms.set_as_homepage')  }}
                    </BaseButton>
                </template>

            </BaseForm>
        </template>
    </Modal>
        <teleport to="#app">
            <PagesSelection :select="true"  :opened="openedParentSelection" @close="openParentSelection(false)" @pickItem="setParent({parent: $event})"/>
        </teleport>
</template>

<script>
import BaseForm from '@common/components/crud/BaseForm.vue'
import Modal from "@common/components/Modal";
import useFormData from '@common/composable/BaseFormData';
import { useI18n } from 'vue-i18n';
import { watchEffect,ref, computed, defineAsyncComponent } from 'vue';
import useProject from '@admin/composable/ProjectModel';
import { useStore } from 'vuex';
import FilePickCell from "@admin/components/cms/file/FilePickCell";

export default {
    name: "ContentPageForm",
    components: {
        BaseForm,
        Modal,
        FilePickCell,
        PagesSelection: defineAsyncComponent(() => import(/* webpackChunkName: "pages-selection" */'@admin/components/cms/content/PagesSelection')),
    },
    emits: ['close',"updateValues"],
    props: {
        opened: Boolean,
        baseData: {
            type: Object,
            default: () => null
        },
        store: {
            type: String,
            default: () => "content_page",
        },
        type: {
            type: String,
            default: () => "page"
        }
    },
    setup(props,context){       
        const i18n = useI18n();          
        const { data,setRows,store_action,formInit,formActions,code } = useFormData();

        const createItem = ref(false);

        const languages = computed(() => store.getters['language/getItems']);
        // data.value = props.baseData;
        
        const { 
            hasExtension
        } = useProject();   

        const statusOptions = [
            {
                title: i18n.t('controls.active'),
                code: 20,
            },
            {
                title: i18n.t('controls.disabled'),
                code: 0,
            },
        ];
        if(hasExtension("auth")){
            statusOptions.push(
                {
                    title: i18n.t('cms.private_access'),
                    code: 10,
                },
                {
                    title: i18n.t('cms.only_public'),
                    code: 30,
                }
            )
        }

        const languageOptions = computed(() => {
            let langs = [];
            for(let language of languages.value){
                langs.push({
                    title: language.name,
                    code: language.item_key
                })
            }
            return langs;
        });

        
        const openedParentSelection = ref(false);
        const openParentSelection = (value) => {
            openedParentSelection.value = value;
        }

        const setParent = ({parent}) => {
            if(parent && parent.code){
                data.value.parent = parent;
                data.value.parentCode = parent.code;
            } else {
                data.value.parent = null;
                data.value.parentCode = null;
            }
        };

        const rows = computed(() => {
            let _rows = [
                {
                    labels: [i18n.t('cms.title')],
                    items: [
                        {
                            title: i18n.t('cms.title'),
                            type: 'text',
                            key: "title",
                            class: "w-xl",
                            required:true
                        }
                    ]
                },
                {
                    labels: [i18n.t('cms.header')],
                    items: [
                        {
                            title: i18n.t('cms.header'),
                            type: 'text',
                            key: "header",
                            class: "w-xl",
                        }
                    ]
                },
                {
                    labels: [i18n.t('cms.language')],
                    items: [
                        {
                            title: i18n.t('cms.language'),
                            type: 'select',
                            key: "language",
                            class: "w-l",
                            required: true,
                            options: () => languageOptions.value
                        }
                    ]
                },
                {
                    labels: [i18n.t('controls.status')],
                    items: [
                        {
                            title: i18n.t('controls.status'),
                            type: 'select',
                            key: "status",
                            class: "w-l",
                            required: true,
                            options: () => statusOptions
                        }
                    ]
                },
            ];
            let _classes = store.getters['project/getClassList']('layout');
            if(_classes){
                let _classOptions = [];
                for(let _class of _classes){
                    _classOptions.push({
                        title: _class.title,
                        code: _class.value,
                    })
                }
                _rows.push({
                    labels: [i18n.t('cms.html_class')],
                    items: [
                        {
                            title: i18n.t('cms.html_class'),
                            type: 'select',
                            key: "layout_class",
                            class: "w-l",
                            options: () => _classOptions
                        }
                    ]
                })
            }

            // seo + breadcrumbs
            _rows = _rows.concat([
                // todo breadcrumbs
                // {
                //     title: i18n.t('cms.breadcrumbs'),
                // },
                // {
                //     labels: [i18n.t('cms.breadcrumb_title')],
                //     items: [
                //         {
                //             title: i18n.t('cms.breadcrumb_title'),
                //             type: 'text',
                //             key: "breadcrumb_title",
                //             class: "w-xl",
                //         }
                //     ]
                // },
                // {
                //     labels: [i18n.t('cms.parent_page')],
                //     items: [
                //         {
                //             title: i18n.t('cms.set_parent_page'),
                //             content: () => i18n.t('controls.select'),
                //             type: 'button',
                //             action: () => openParentSelection(true),
                //         },
                //         {
                //             title: i18n.t('cms.go_to_page'),
                //             content: () => data.value.parent ? data.value.parent.title : '',
                //             type: 'link',
                //             link: () => data.value.parent ? 'url.project/:project_code/url.content_pages/!'+ data.value.parent.code : '',
                //             class: "page-title"
                //         }
                //     ]
                // },
                {
                    title: "SEO",
                },
                {
                    labels: [i18n.t('cms.url')],
                    items: [
                        {
                            title: i18n.t('cms.url'),
                            type: 'text',
                            key: "seo.url",
                            class: "w-xl",
                        }
                    ]
                },
                {
                    labels: [i18n.t('cms.meta_description')],
                    items: [
                        {
                            title: i18n.t('cms.meta_description'),
                            type: 'textarea',
                            key: "seo.description",
                            class: "w-xl",
                        }
                    ]
                },
                // {
                //     labels: ["-"],
                //     items: [
                //         {
                //             title: i18n.t('cms.detailed_settings_seo'),
                //             content: () => i18n.t('controls.detailed_settings'),
                //             type: 'button',
                //             action: () => openParentSelection(true),
                //             class: "w-xl",
                //         },
                //     ]
                // },
            ]);

            if(props.type === 'page'){
                _rows = _rows.concat([
                    {
                        divider: true,
                    },
                    {
                        custom: true,
                        slot_name: "homepage_row"
                    },
                ]);
            }
            return _rows;
        })

        const closeForm = () => {
            // data.value = {};
            context.emit('close');
        }

        const store = useStore();
        

        const set_homepage = () => {
            store.dispatch('content_page/setHomepage',{code: code.value});
            store.commit('page_editor/updateValues',{values: {homepage: 1}});
            closeForm();
        }

        
        watchEffect(() => {
            // console.log(props.baseData)
            if(props.baseData === null  || !props.opened){
                return;
            }
            formInit();
            data.value = Object.assign({},props.baseData);
            data.value.seo = Object.assign({},props.baseData.seo);
            // console.log(data.value);
            // data.value = props.baseData;
            if(!props.store){
                code.value = props.baseData.code;
                store_action.value = '';
                createItem.value = false;
            } else if (!data.value.code){
                createItem.value = true;
                store_action.value = props.store+'/createItem';
                data.value.status = 20;
                if(languages.value.length > 0){
                    data.value.language = languages.value[0].item_key;
                }
            } else {
                code.value = props.baseData.code;
                store_action.value = props.store+'/updateItem';
                createItem.value = false;
            }
            setRows(rows.value);
            formActions.value.afterSubmit = () =>  {
                context.emit('updateValues',data.value);
                closeForm()
            };
            formActions.value.cancelForm = () =>  closeForm();
            formActions.value.deleteItem = () => {
                store.dispatch(props.store+'/deleteItem',{code: code.value});
                closeForm();
            };
        });

        const setImage = (event) => {
            data.value.image = event;
            data.value.imageCode = event.code;
        };

        return {
            closeForm,
            createItem,
            data,
            set_homepage,
            setImage,
            openedParentSelection,
            openParentSelection,
            setParent
        }

    }
}
</script>

<style scoped>
.set_homepage{
    margin-top: 1rem;
    float: right;
}
.file-pick-cell{
    height: 10rem;
}

::v-deep(.page-title){    
    width: 100%;
}
::v-deep(.page-title a){    
    width: 100%;
    padding: 0.5rem;
    font-weight: 700;
}

</style>